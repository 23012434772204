import React from 'react';
import tw, {styled} from 'twin.macro';
import data from '../../../config/site_data.json';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Container, Section} from '../../Grid';
import {Heading} from '../../Typography';

import RegularList from './RegularList';
import BookNowController from '../../BookNowController';
import {Button} from '../../Button';

const Wrapper = styled(Section)``;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary lg:mb-24`};
  margin-bottom: ${props => props.marginBottom} !important;
  font-size: 24px;
  color: #224b45;
  line-height: 32px;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
    line-height: 42px;
  }
`;

const ListWrapper = styled.div`
  ${tw`mt-8`}
`;

export const Description = styled.p`
  ${tw`font-medium text-extra mt-4`}
  font-size: 15px;
  color: #224b45;
  line-height: 24px;
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign};
  a {
    color: #039259;
    text-decoration: underline;
  }

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 19px;
  }
`;

export const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 46px;
  margin-bottom: 20px;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
  }
`;

const RegularCleaning = () => {
  const {t} = useTranslation();

  return (
    <Wrapper>
      <Container>
        <StyledHeading style={{marginTop: 60}} marginBottom="30px">
          {t('regular_cleaning_home_heading')}
        </StyledHeading>
        <Description
          marginBottom="50px"
          dangerouslySetInnerHTML={{__html: t('regular_cleaning_home_text')}}
        />
        <BookNowController>
          <StyledButton>{t('regular_cleaning_home_button')}</StyledButton>
        </BookNowController>
      </Container>
    </Wrapper>
  );
};

export default RegularCleaning;
