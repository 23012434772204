import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import tw, { styled, css, theme } from 'twin.macro';
import useWindowSize from '../hooks/useWindowSize';
import DynamicImage from './DynamicImage';

const topPositionStyle = css`
  transform: translate(-50%, calc(-100% - 5px));

  @media ${props => props.theme.screens.md} {
    transform: translate(calc(-50% + 8px), calc(-100% - 5px));
  }
`;

const bottomPositionStyle = css`
  transform: translate(-50%, 10px);

  @media ${props => props.theme.screens.md} {
    transform: translate(calc(-50% + 8px), 20px);
  }

  @media ${props => props.theme.screens.lg} {
    transform: translate(calc(-50% + 8px), 25px);
  }
`;

const positionStyles = {
  top: topPositionStyle,
  bottom: bottomPositionStyle,
};

interface IHotspotPoint {
  x: number;
  y: number;
  content: string;
  position?: keyof typeof positionStyles;
}

interface IHotspotImage {
  filename: string;
  hotspots?: [IHotspotPoint] | [];
}

const HotspotWrapper = styled.div`
  ${tw`relative`}
`;

const StyledPoint = styled.span<{ x: number; y: number }>`
  ${tw`bg-primary border md:border-2 border-white rounded-full cursor-pointer  transition-opacity`}

  @keyframes pulse {
    to {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
  }

  position: absolute;

  left: ${props => props.x + '%'};
  top: ${props => props.y + '%'};
  transform: translate3d(0, 0, 0);

  z-index: 10;

  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);

  width: 8px;
  height: 8px;
  border: 1px solid ${props => props.theme.colors.white};
  background-color: #009259 !important;

  @media ${props => props.theme.screens.md} {
    width: 16px;
    height: 16px;
  }
  @media ${props => props.theme.screens.lg} {
    width: 20px;
    height: 20px;
  }
`;

const Content = styled.span<{
  open: boolean;
  position: string;
  x: number;
  y: number;
}>`
  ${tw`block absolute bg-white text-secondary pointer-events-none transition-opacity text-center`}

  left: ${props => props.x + '%'};
  top: ${props => props.y + '%'};
  opacity: ${props => (props.open ? 1 : 0)};

  ${props => positionStyles[props.position]};
  width: max-content;
  max-width: 100px;

  z-index: 11;

  box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  color: #224B45;
  font-size: 15px;
  font-weight: bold;
  padding: 0.3em 1em;
  max-width: 180px;
  @media ${props => props.theme.screens.md} {
    max-width: 260px;
    border-radius: 16px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 17px;
  }
`;

const Point: React.FC<{
  content: string;
  selected: string;
  x: number;
  y: number;
  position?: keyof typeof positionStyles;
}> = ({ position = 'bottom', selected, content, x, y }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const size = useWindowSize();

  useEffect(() => {
    const breakpoint = theme('screens.md').replace('px', '');

    setOpen(true);

    if (size.width < breakpoint && selected != content) {
      setOpen(false);
    }
  }, [size, selected]);

  return (
    <React.Fragment>
      <StyledPoint x={x} y={y} onClick={() => setOpen(!open)} />
      <Content open={open} x={x} y={y} position={position}>
        {t(content)}
      </Content>
    </React.Fragment>
  );
};

const HotspotPoint: React.FC<{
  content: string;
  position?: keyof typeof positionStyles;
  selected: string;
}> = ({ children, position = 'bottom', selected, content }) => {
  const [open, setOpen] = useState(true);

  const size = useWindowSize();

  useEffect(() => {
    const breakpoint = theme('screens.md').replace('px', '');

    setOpen(true);

    if (size.width < breakpoint && selected != content) {
      setOpen(false);
    }
  }, [size, selected]);

  return (
    <StyledPoint onClick={() => setOpen(!open)}>
      <Content position={position} open={open}>
        {content}
      </Content>
    </StyledPoint>
  );
};

const HotspotImage: React.FC<IHotspotImage> = ({ filename, hotspots = [] }) => {
  const [selected, setSelected] = useState('');

  return (
    <HotspotWrapper>
      <DynamicImage filename={filename} />
      {hotspots.map((hotspot: IHotspotPoint) => {
        const { x, y, content, position } = hotspot;
        return (
          <span onClick={() => setSelected(content)} key={content}>
            <Point
              x={x}
              y={y}
              content={content}
              position={position}
              selected={selected}
            />
          </span>
        );
      })}
    </HotspotWrapper>
  );
};

export default HotspotImage;
